
import { ALL_INVENTORIES, ADD_NEW_INVENTORY, UPDATE_INVENTORY, DELETE_INVENTORY, FETCH_INVENTORIES_BY_USER, FETCH_INVENTORY, START_LOADING, END_LOADING } from '../actions/constants'

const inventories = (state = { isLoading: true, inventories: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_INVENTORIES:
        return {
          ...state,
          inventories: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_INVENTORIES_BY_USER:
        return { ...state, inventories: action.payload };

      case FETCH_INVENTORY:
        return { ...state, inventory: action.payload.inventory };
      case ADD_NEW_INVENTORY:
        return { ...state, inventories: [...state.inventories, action.payload] };
      case UPDATE_INVENTORY:
        return { ...state, inventories: state.inventories.map((inventory) => (inventory._id === action.payload._id ? action.payload : inventory)) };
      case DELETE_INVENTORY:
        return { ...state, inventories: state.inventories.filter((inventory) => inventory._id !== action.payload) };
      default:
        return state;
    }
  };

  export default inventories




//   const inventories =(inventories =[], action) => {
//     switch (action.type) {
//         case ALL_INVENTORIES:
//             return action.payload

//         case FETCH_INVENTORIES_BY_USER:
//             return action.payload

//         case ADD_NEW_INVENTORY:
//             return [...inventories, action.payload]

//         case UPDATE_INVENTORY:
//             return inventories.map((inventory) => inventory._id === action.payload ? action.payload : inventory)
        
//         case DELETE_INVENTORY: 
//         return inventories.filter((inventory) => inventory._id !== action.payload)
        
//         default:
//             return inventories;
//     }
// }

// export default inventories
