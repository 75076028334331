import * as api from '../api/index'

import { ADD_NEW_ROUTE, UPDATE_ROUTE, DELETE_ROUTE, FETCH_ROUTES_BY_USER, FETCH_ROUTE, START_LOADING, END_LOADING } from './constants'


export const getRoute = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchRoute(id);
      dispatch({ type: FETCH_ROUTE, payload: { route: data } });

    } catch (error) {
      console.log(error);
    }
  };


export const getRoutesByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
      const  { data: { data } } = await api.fetchRoutesByUser(searchQuery)
      
      dispatch({ type: FETCH_ROUTES_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createRoute =(route, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addRoute(route)
        dispatch({ type: ADD_NEW_ROUTE, payload: data })
        openSnackbar("Route added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateRoute =(id, route, openSnackbar) => async (dispatch) => {

    const { data } = await api.updateRoute(id, route)
    dispatch({ type: UPDATE_ROUTE, payload: data })
    openSnackbar("Route updated successfully")
    try {
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteRoute =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteRoute(id)

        dispatch({type: DELETE_ROUTE, payload: id})
        openSnackbar("Route deleted successfully")
    } catch (error) {
        console.log(error)
    }
}