 /* eslint-disable */
import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { useDispatch, useSelector } from 'react-redux'
import { createInventory, updateInventory } from '../../actions/inventoryActions'
import { useSnackbar } from 'react-simple-snackbar'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddInventory = ({ setOpen, open, currentId, setCurrentId }) => {
    const location = useLocation()
    const [inventoryData, setInventoryData] = useState({ brand: '', modelNumber: '', cost: '', price: '', type: '', description: '', userId: [] })
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const dispatch = useDispatch()
    const inventory = useSelector((state)=> currentId ? state.inventories.inventories.find((c) => c._id === currentId) : null)
    // eslint-disable-next-line 
    const [openSnackbar, closeSnackbar] = useSnackbar()

    useEffect(() => {
      if(inventory) {
        setInventoryData(inventory)
      }
    }, [inventory])

    useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('profile')))
      // setInventoryData({...inventoryData, userId: user?.result?._id})
    },[location])


    useEffect(() => {
      var checkId = user?.result?._id
      if(checkId !== undefined) {
        setInventoryData({...inventoryData, userId: [checkId]})
      } else {
        setInventoryData({...inventoryData, userId: [user?.result?.googleId]})
      }
      
    },[location])

  const formatDollarsToCents = function (value) {
      value = `${value}`.replace(/[^\d.-]/g, '');
      if (value && value.includes('.')) {
          value = value.substring(0, value.indexOf('.') + 3);
      }
  
      return value ? Math.round(parseFloat(value) * 100) : 0;
  };
  
  const formatCentsToDollars = function (value) {
      value = `${value}`.replace(/[^\d.-]/g, '');
      value = parseFloat(value);
      return value ? value / 100 : 0;
  };
  
  // Create our number formatter.
  const dollar_formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
  });

    const handleSubmitInventory =(e)=> {
        e.preventDefault()
        if(currentId) {
          dispatch(updateInventory(currentId, inventoryData, openSnackbar))
        } else {
          dispatch(createInventory(inventoryData, openSnackbar))
        }
        
        handleClose()
    }

  const clear =() => {
    setCurrentId(null) 
    setInventoryData({ brand: '', modelNumber: '', cost: '', price: '', type: '', description: '', userId: [] })
  }
    
  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
}


  return (
    <div>
        <form >
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}}>
            {currentId? 'Edit Inventory' : 'Add New Inventory'}
            </DialogTitle>
            <DialogContent dividers>


            <div className="customInputs">

              <input 
                placeholder="Model Number" 
                style={inputStyle} 
                name='modelNumber' 
                type='text' 
                onChange={(e) => setInventoryData({...inventoryData, modelNumber: e.target.value})}
                value={inventoryData.modelNumber}
              />

              <input 
                placeholder="Quantity" 
                style={inputStyle} 
                name='quantity'
                type='number'
                onChange={(e) => setInventoryData({...inventoryData, quantity: e.target.value})}
                defaultValue={inventoryData.quantity}
              />

              <input 
                placeholder="Cost" 
                style={inputStyle} 
                name='cost'
                type='text'
                onChange={(e) => setInventoryData({...inventoryData, cost: formatDollarsToCents(e.target.value)})}
                defaultValue={inventoryData.cost !== '' ? dollar_formatter.format(formatCentsToDollars(inventoryData.cost)) : inventoryData.cost}
              />
              
            <input 
                placeholder="Price" 
                style={inputStyle} 
                name='price' 
                type='text'  
                onChange={(e) => setInventoryData({...inventoryData, price : formatDollarsToCents(e.target.value)})}
                defaultValue={inventoryData.price !== '' ? dollar_formatter.format(formatCentsToDollars(inventoryData.price)) : inventoryData.price} 
              />
          </div>

            </DialogContent>
            <DialogActions>
            <Button  onClick={handleSubmitInventory}  variant="contained" style={{marginRight: '25px'}} >
                Save 
            </Button>
            </DialogActions>
      </Dialog>
        </form>
    </div>
  );
}

export default AddInventory