import React, { setState, useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import styles from "./OrderSheet.module.css";
import { makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useLocation } from "react-router-dom";
import { getInvoicesByUser } from "../../actions/invoiceActions";
import { getWhitesByUser } from "../../actions/whiteActions";
import { getProfilesByUser } from "../../actions/profile";
import { getRet, createRet, updateRet } from '../../actions/returnActions'
import Spinner from "../Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import axios from "axios";
import { saveAs } from "file-saver";
import ProgressButton from "react-progress-button";
import { useMediaQuery } from "@material-ui/core";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const OrderSheet = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => {
    return state.invoices
  });
  const {whites} = useSelector((state) => {
    return state.whites
  })
  let {ret} = useSelector(state => {
    return state.returns});
  const user = JSON.parse(localStorage.getItem("profile"));
  const isLoading = useSelector((state) => state.routes.isLoading);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [sendStatus, setSendStatus] = useState(null);
  const [sendLawStatus, setSendLawStatus] = useState(null);
  const [returnItems, setReturnItems] = useState(ret ? ret.items : []);
  const [selectedDate, setSelectedDate] = useState(() => {
    return moment().format("MM/DD/yyyy");
  });
  const [company, setCompany] = useState({});
  const [filteredInvoices, setFilteredInvoices] = useState(() => {
    let filtered = invoices.filter((i) => {
      return moment(i.receivedByDate).format("MM/DD/yyyy") === selectedDate;
    });
    return filtered;
  });
  const [filteredWhites, setFilteredWhites] = useState(() => {
    let filtered = whites.filter((i) => {
      return moment(i.receivedByDate).format("MM/DD/yyyy") === selectedDate;
    });
    return filtered;
  });
  const [items, setItems] = useState([]);
  const [whiteItems, setWhiteItems] = useState([]);
  const [dnoItems, setDNOItems] = useState([]);
  const { profiles } = useSelector((state) => state.profiles);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
        // get by userx
        // dispatch(getInvoicesByUser({search: user?.result._id || user?.result?.googleId}));
        // get white or invoice
        dispatch(getInvoicesByUser({search: false}));
        dispatch(getWhitesByUser({search: false}));
    dispatch(
      getProfilesByUser({ search: user?.result?._id || user?.result.googleId })
    );
    let dt = Date.parse(selectedDate)
    dispatch(getRet(dt))
    // eslint-disable-next-line
  }, [location, dispatch]);

  useEffect(() => {
    setFilteredInvoices(() => {
      let filtered = invoices.filter((i) => {
        return moment(i.receivedByDate).format("MM/DD/yyyy") === selectedDate;
      });
      return filtered;
    });

    setFilteredWhites(() => {
      let filtered = whites.filter((i) => {
        return moment(i.receivedByDate).format("MM/DD/yyyy") === selectedDate;
      });
      return filtered;
    });
    if (ret) {
      setReturnItems(ret.items)
    } else {
      setReturnItems([])
    }
  }, [selectedDate, invoices, ret]);

  useEffect(() => {
    setItems(generateOrderSheet());
    setWhiteItems(generateWhiteOrderSheet());
    setDNOItems(generateDNOSheet());
  }, [filteredInvoices]);
  


  const generateOrderSheet = () => {
    let productsMap = {};
    filteredInvoices.forEach((invoice) => {
      invoice.items.forEach((i) => {
        if (!i.doNotOrder) {
          if ((!productsMap.hasOwnProperty(i.modelNumber) && !i.lockup) || (!productsMap.hasOwnProperty(`#${i.modelNumber}`) && i.lockup)) {
            let pCost = dollar_formatter.format(i.unitCost);
            let pPrice = dollar_formatter.format(i.unitPrice);
            let pLockup = i.lockup ? `#${i.modelNumber}` : i.modelNumber
            productsMap[pLockup] = {
              quantity: i.quantity,
              cost: pCost,
              modelNumber: pLockup,
              price: pPrice
            };
          } else {
            let pLockup = i.lockup ? `#${i.modelNumber}` : i.modelNumber
            let quantity = parseInt(productsMap[pLockup]["quantity"]);
            productsMap[pLockup]["quantity"] = (
              quantity + parseInt(i.quantity)
            ).toString();
          }
        }
      });
    });
    return Object.keys(productsMap).map((key) => {
      return productsMap[key];
    });
  };

  const generateWhiteOrderSheet = () => {
    let productsMap = {};
    filteredWhites.forEach((invoice) => {
      invoice.items.forEach((i) => {
        if (!i.doNotOrder) {
          if ((!productsMap.hasOwnProperty(i.modelNumber) && !i.lockup) || (!productsMap.hasOwnProperty(`#${i.modelNumber}`) && i.lockup)) {
            let pCost = dollar_formatter.format(i.unitCost);
            let pPrice = dollar_formatter.format(i.unitPrice);
            let pLockup = i.lockup ? `#${i.modelNumber}` : i.modelNumber
            productsMap[pLockup] = {
              quantity: i.quantity,
              cost: pCost,
              modelNumber: pLockup,
              price: pPrice
            };
          } else {
            let pLockup = i.lockup ? `#${i.modelNumber}` : i.modelNumber
            let quantity = parseInt(productsMap[pLockup]["quantity"]);
            productsMap[pLockup]["quantity"] = (
              quantity + parseInt(i.quantity)
            ).toString();
          }
        }
      });
    });
    return Object.keys(productsMap).map((key) => {
      return productsMap[key];
    });
  };

  const generateDNOSheet = () => {
    let productsMap = {};
    filteredInvoices.forEach((invoice) => {
      invoice.items.forEach((i) => {
        if (i.doNotOrder) {
          if (!productsMap.hasOwnProperty(i.modelNumber)) {
            let pCost = dollar_formatter.format(i.unitCost);
            let pPrice = dollar_formatter.format(i.unitPrice);
            productsMap[i.modelNumber] = {
              quantity: i.quantity,
              modelNumber: i.modelNumber,
              cost: pCost,
              price: pPrice
            };
          } else {
            let quantity = parseInt(productsMap[i.modelNumber]["quantity"]);
            productsMap[i.modelNumber]["quantity"] = (
              quantity + parseInt(i.quantity)
            ).toString();
          }
        }
      });
    });
    return Object.keys(productsMap).map((key) => {
      return productsMap[key];
    });
  };

  const sendOrderSheet = (e, emailList, flag) => {
    e.preventDefault();
    flag === 'L' ? setSendLawStatus("loading") : setSendStatus("loading");
    axios
      .post(`${process.env.REACT_APP_API}/pdfs/send-order-sheet`, {
        sentDate: moment().format("MM/DD/yyyy"),
        selectedDate: selectedDate,
        returnRows: returnItems,
        rows: items,
        email: emailList,
        flag: flag,
      })
      .then(() => {
        flag === 'L' ? setSendLawStatus("success") : setSendStatus("success");
      })
      .catch((error) => {
        console.log(error);
        flag === 'L' ? setSendLawStatus("error") : setSendStatus("error");
      });
  };

  // Create our number formatter.
  const dollar_formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const formatDollarsToCents = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }

    return value ? Math.round(parseFloat(value) * 100) : 0;
  };

  const formatCentsToDollars = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, "");
    value = parseFloat(value);
    return value ? value / 100 : 0;
  };

  const handleDateChange = (value) => {
    if (value !== null) {
      let val = moment(value).format("MM/DD/yyyy");
      let dt = Date.parse(val)
      dispatch(getRet(dt))
      setSelectedDate(val);
    }
  };

  if (!user) {
    history.push("/login");
  }

  const dnoHeaderRow = {
    rowId: 'dnoHeader',
    cells: [
      {
        type: "header",
        text: "Model #",
      },
      {
        type: "header",
        text: "Qty",
      },
    ]
  }

  const headerRow = {
    rowId: "header",
    cells: [
      {
        type: "header",
        text: "Model #",
      },
      {
        type: "header",
        text: "Qty",
      },
      {
        type: "header",
        text: "Cost",
      },
    ],
  };

  const headerReturnRow = {
    rowId: "returnsHeader",
    cells: [
      {
        type: "header",
        text: "Model #",
      },
      {
        type: "header",
        text: "Qty",
      },
      {
        type: "header",
        text: "Return",
      },
      {
        type: "header",
        text: "Ticket #",
      },
    ],
  }

  const mobileHeaderRow = {
    rowId: "header",
    cells: [
      {
        type: "header",
        text: "Model #",
      },
      {
        type: "header",
        text: "Qty",
      },
      {
        type: "header",
        text: "Cost",
      },
    ],
  };

  let totalCostCents = 0;
  let totalCostInventoryCents = 0;
  let totalCostInventory = 0;
  let totalCents = 0;
  let totalCost = "";
  let profit = "";
  let revenue = "";
  let bought = "";

  let totalCostCentsWhites = 0;
  let totalCostInventoryCentsWhites = 0;
  let totalCostInventoryWhites = 0;
  let totalCentsWhites = 0;
  let totalCostWhites = "";
  let profitWhites = "";
  let revenueWhites = "";
  let boughtWhites = "";
  
  const calculateTotal = () => {
    totalCostCents = 0;
    for (var i = 0; i < filteredInvoices.length; i += 1) {
      let invoice = filteredInvoices[i];
      let cTotal = formatDollarsToCents(invoice.total);
      totalCents += cTotal;
      for (var j = 0; j < invoice.items.length; j ++) {
        let item = invoice.items[j];
        if (!item.doNotOrder) {
          let qty = parseInt(item.quantity)
          let costCents = formatDollarsToCents(item.unitCost)
          totalCostCents += qty * costCents;
        } else {
          let qty = parseInt(item.quantity)
          let costCents = formatDollarsToCents(item.unitCost)
          totalCostInventoryCents += qty * costCents;
        }
      }
    }
    let profitCents = totalCents - (totalCostCents + totalCostInventoryCents);
    profit = dollar_formatter.format(formatCentsToDollars(profitCents));
    revenue = dollar_formatter.format(formatCentsToDollars(totalCents));
    totalCost = dollar_formatter.format(formatCentsToDollars(totalCostCents + totalCostInventoryCents));
    totalCostInventory = dollar_formatter.format(formatCentsToDollars(totalCostInventoryCents));
    bought = dollar_formatter.format(formatCentsToDollars(totalCostCents));
  };

  const calculateTotalWhites = () => {
    totalCostCentsWhites = 0;
    for (var i = 0; i < filteredWhites.length; i += 1) {
      let invoice = filteredWhites[i];
      let cTotal = formatDollarsToCents(invoice.total);
      totalCentsWhites += cTotal;
      for (var j = 0; j < invoice.items.length; j ++) {
        let item = invoice.items[j];
        if (!item.doNotOrder) {
          let qty = parseInt(item.quantity)
          let costCents = formatDollarsToCents(item.unitCost)
          totalCostCentsWhites += qty * costCents;
        } else {
          let qty = parseInt(item.quantity)
          let costCents = formatDollarsToCents(item.unitCost)
          totalCostInventoryCentsWhites += qty * costCents;
        }
      }
    }
    let profitCents = totalCentsWhites - (totalCostCentsWhites + totalCostInventoryCentsWhites);
    profitWhites = dollar_formatter.format(formatCentsToDollars(profitCents));
    revenueWhites = dollar_formatter.format(formatCentsToDollars(totalCentsWhites));
    totalCostWhites = dollar_formatter.format(formatCentsToDollars(totalCostCentsWhites + totalCostInventoryCentsWhites));
    totalCostInventoryWhites = dollar_formatter.format(formatCentsToDollars(totalCostInventoryCentsWhites));
    boughtWhites = dollar_formatter.format(formatCentsToDollars(totalCostCentsWhites));
  };

  const getRows = () => {
    return [
      headerRow,
      ...items.map((p, index) => ({
        rowId: index,
        cells: [
          { type: "text", text: p.modelNumber },
          { type: "text", text: p.quantity },
          { type: "text", text: p.cost },
        ],
      })),
      {
        rowId: "totalTitle",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Total` },
        ],
      },
      {
        rowId: "totalBought",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Bought: ${bought}` },
        ],
      },
      {
        rowId: "totalInventory",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Inventory: ${totalCostInventory}` },
        ],
      },
      {
        rowId: "total",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Cost: ${totalCost}` },
        ],
      },
      {
        rowId: "revenue",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Revenue: ${revenue}` },
        ],
      },
      {
        rowId: "Profit",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Profit: ${profit}` },
        ],
      },
    ];
  };

  const getWhiteRows = () => {
    return [
      headerRow,
      ...whiteItems.map((p, index) => ({
        rowId: index,
        cells: [
          { type: "text", text: p.modelNumber },
          { type: "text", text: p.quantity },
          { type: "text", text: p.cost },
        ],
      })),
      {
        rowId: "totalTitle",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Total` },
        ],
      },
      {
        rowId: "totalBought",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Bought: ${boughtWhites}` },
        ],
      },
      {
        rowId: "totalInventory",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Inventory: ${totalCostInventoryWhites}` },
        ],
      },
      {
        rowId: "total",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Cost: ${totalCostWhites}` },
        ],
      },
      {
        rowId: "revenue",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Revenue: ${revenueWhites}` },
        ],
      },
      {
        rowId: "Profit",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Profit: ${profitWhites}` },
        ],
      },
    ];
  };

  const getReturnRows = () => {
      return [
        headerReturnRow,
        ...returnItems.map((p, index) => ({
          rowId: index,
          cells: [
            { type: "text", text: p.modelNumber },
            { type: "text", text: p.quantity.toString() },
            { type: "dropdown", isOpen: p.isOpen, values: [{label: "", value: ""}, {label: "Exchange", value: "Exchange"}, {label: "Cancel", value: "Cancel"}, {label: "ASAP", value: "ASAP"}], selectedValue: p.returnType},
            { type: "text", text: p.ticketNumber.toString()}
          ],
        })),
      ]
    // let dt = Date.parse(selectedDate)
    // let response = await axios.get(
    //   `${process.env.REACT_APP_API}/rets?returnDate=${dt}`
    // ).then(response => {
      
    // })
    // return response;
  };

  const getDNORows = () => {
    return [
      dnoHeaderRow,
      ...dnoItems.map((p, index) => ({
        rowId: index,
        cells: [
          { type: "text", text: p.modelNumber },
          { type: "text", text: p.quantity },
        ],
      })),
    ];
  }
  const getMobileRows = () => {
    return [
      mobileHeaderRow,
      ...items.map((p, index) => ({
        rowId: index,
        cells: [
          { type: "text", text: p.modelNumber },
          { type: "text", text: p.quantity },
          { type: "text", text: p.cost },
        ],
      })),
      {
        rowId: "totalTitle",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Total` },
        ],
      },
      {
        rowId: "totalBought",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Bought: ${bought}` },
        ],
      },
      {
        rowId: "totalInventory",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Inventory: ${totalCostInventory}` },
        ],
      },
      {
        rowId: "total",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Cost: ${totalCost}` },
        ],
      },
      {
        rowId: "revenue",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Revenue: ${revenue}` },
        ],
      },
      {
        rowId: "Profit",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Profit: ${profit}` },
        ],
      },
    ];
  };

  const getWhiteMobileRows = () => {
    return [
      mobileHeaderRow,
      ...whiteItems.map((p, index) => ({
        rowId: index,
        cells: [
          { type: "text", text: p.modelNumber },
          { type: "text", text: p.quantity },
          { type: "text", text: p.cost },
        ],
      })),
      {
        rowId: "totalTitle",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Total` },
        ],
      },
      {
        rowId: "totalBought",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Bought: ${boughtWhites}` },
        ],
      },
      {
        rowId: "totalInventory",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Inventory: ${totalCostInventoryWhites}` },
        ],
      },
      {
        rowId: "total",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Cost: ${totalCostWhites}` },
        ],
      },
      {
        rowId: "revenue",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Revenue: ${revenueWhites}` },
        ],
      },
      {
        rowId: "Profit",
        cells: [
          { type: "text", text: "" },
          { type: "text", text: "" },
          { type: "text", text: `Profit: ${profitWhites}` },
        ],
      },
    ];
  };

  calculateTotal();
  calculateTotalWhites();

  const rows = getRows(items);
  const whiteRows = getWhiteRows(whiteItems);
  const dnoRows = getDNORows(dnoItems);
  const mobileRows = getMobileRows(items);
  const whieMobileRows = getWhiteMobileRows(whiteItems);
  const returnRows = getReturnRows()
  const cols = [
    { columnId: "modelNumber", width: 250 },
    { columnId: "quantity", width: 250 },
    { columnId: "cost", width: 250 },
  ];

  const returnCols = [
    { columnId: "modelNumber", width: 250 },
    { columnId: "quantity", width:250 },
    { columnId: "returnType", width: 250 },
    { columnId: "ticketNumber", width: 250}
  ];

  const dnoCols = [
    { columnId: "modelNumber", width: 250 },
    { columnId: "quantity", width: 100 },
  ]

  const returnMobileCols = [
    { columnId: "modelNumber", width: 150 },
    { columnId: "quantity", width: 25 },
    { columnId: "returnType", width: 150 },
    { columnId: "ticketNumber", width: 100}
  ];

  const mobileCols = [
    { columnId: "modelNumber", width: 150 },
    { columnId: "quantity", width: 75 },
    { columnId: "cost", width: 150 },
  ];

  const applyChanges = (changes, prev) => {
    changes.forEach((change) => {
      console.log(change);
      const rowIndex = change.rowId;
      const fieldName = change.columnId;
      if (change.type === 'dropdown') {
        if (change.newCell.selectedValue !== change.previousCell.selectedValue) {
          prev[rowIndex][fieldName] = change.newCell.selectedValue;
        }
        prev[rowIndex]['isOpen'] = change.newCell.isOpen;
      } else {
        prev[rowIndex][fieldName] = change.newCell.text;
      }
      
    });
    return [...prev];
  };

  const handleChanges = (changes) => {
    setItems((prev) => applyChanges(changes, prev));
  };

  const handleReturnChanges = (changes) => {
    setReturnItems((prev) => applyChanges(changes, prev));
  }

  const handleDNOChanges = (changes) => {
    setDNOItems((prev) => applyChanges(changes, prev));
  }

  const handleAddDNORowClick = () => {
    setDNOItems((prev) => {
      return [
        ...prev,
        {modelNumber: '', quantity: '0'}
      ]
    })
  }

  const handleAddReturnRowClick = () => {
    setReturnItems((prev) => {
      console.log(prev);
      return [
        ...prev,
        { modelNumber: "", quantity: "", returnType: "", ticketNumber: "", isOpen: false },
      ];
    });
  };

  const handleSaveReturnsClick = () => {
    console.log(returnItems);
    if (ret) {
      dispatch(updateRet(Date.parse(selectedDate),{
        items: returnItems,
      }, openSnackbar))
    } else {
      dispatch(createRet({
        items: returnItems,
        returnDate: Date.parse(selectedDate)
      }, openSnackbar))
    }
  };


  const handleAddRowClick = () => {
    setItems((prev) => {
      return [
        ...prev,
        { quantity: "0", cost: "$0.00", modelNumber: "" },
      ];
    });
  };

  const handleRemoveRowClick = (selectedRowIds) => {
    setItems((prev) => {
      return [...prev.filter((product, idx) => !selectedRowIds.includes(idx))];
    });
  };


  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <Container
      style={{
        width: "100%",
        paddingTop: "70px",
        paddingBottom: "200px",
        minHeight: '100vh',
        border: "none",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="row">
      
        <div className="col-md-6 col-sm-12" style={{textAlign: 'center'}}>
          <h1>Order Sheet</h1>
          <div className={styles.buttons}>
            <button
              // disabled={status === 'Paid' ? true : false}
              className={styles.btn}
              onClick={() => handleAddRowClick()}
            >
              Add Row
            </button>
            <div className={styles.toolBar}>
              <Container>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Filter By Received By Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Container>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
            <div className={styles.buttons}>
              <div>
                <ProgressButton
                  onClick={(e) => sendOrderSheet(e, profiles.emailLawList, "L")}
                  state={sendLawStatus}
                  onSuccess={() =>
                    openSnackbar("Order Sheet sent successfully")
                  }
                >
                  Send to LAW
                </ProgressButton>
              </div>
              <div className={styles.saveButton}>
                <ProgressButton
                  onClick={(e) =>
                    sendOrderSheet(e, profiles.emailWarehouseList, "W")
                  }
                  state={sendStatus}
                  onSuccess={() =>
                    openSnackbar("Order Sheet sent successfully")
                  }
                >
                  Send to Warehouse
                </ProgressButton>
              </div>
            </div>
          </div>
      </div>

      {/* <span>
        Products are displayed from invoices:{" "}
        {filteredInvoices.map((i) => {
          return `${i.invoiceNumber}, `;
        })}
      </span> */}
      
       {
        !matches ?
            <ReactGrid
              rows={mobileRows}
              columns={mobileCols}
              onCellsChanged={handleChanges}
              enableRowSelection
            ></ReactGrid>
            :
            <ReactGrid
              rows={rows}
              columns={cols}
              onCellsChanged={handleChanges}
              enableRowSelection
            ></ReactGrid>
       }
      <div style={{paddingTop: 50, textAlign: 'center'}}>
      <h2>Returns</h2>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className={styles.buttons}>
              <button
                // disabled={status === 'Paid' ? true : false}
                className={styles.btn}
                onClick={() => handleAddReturnRowClick()}
              >
                Add Row
              </button>
              <button
                // disabled={status === 'Paid' ? true : false}
                className={styles.btn}
                onClick={() => handleSaveReturnsClick()}
                disabled={returnItems.length > 0 ? false : true}
              >
                Save Returns
              </button>
            </div>
          </div>
        </div>
        {
          !matches ?
              <ReactGrid
                rows={returnRows}
                columns={returnMobileCols}
                onCellsChanged={handleReturnChanges}
                enableRowSelection
              ></ReactGrid>
              :
              <ReactGrid
              rows={returnRows}
              columns={returnCols}
              onCellsChanged={handleReturnChanges}
              enableRowSelection
          ></ReactGrid>
        }
      
      </div>
      <div style={{paddingTop: 50, textAlign: 'center'}}>
        <h2>Inventory</h2>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className={styles.buttons}>
              <button
                // disabled={status === 'Paid' ? true : false}
                className={styles.btn}
                onClick={() => handleAddDNORowClick()}
              >
                Add Row
              </button>
            </div>
          </div>
        </div>

          <ReactGrid
            rows={dnoRows}
            columns={dnoCols}
            onCellsChanged={handleDNOChanges}
            enableRowSelection
          ></ReactGrid>
      
      </div>
      <div style={{paddingTop: 50, textAlign: 'center'}}>
        <h2>Whites</h2>
        {
        !matches ?
            <ReactGrid
              rows={whieMobileRows}
              columns={mobileCols}
              onCellsChanged={handleChanges}
              enableRowSelection
            ></ReactGrid>
            :
            <ReactGrid
              rows={whiteRows}
              columns={cols}
              onCellsChanged={handleChanges}
              enableRowSelection
            ></ReactGrid>
       }
      </div>
      
      
    </Container>
  );
};

export default OrderSheet;
