
import { ALL_RETS, ADD_NEW_RET, UPDATE_RET, DELETE_RET, FETCH_RETS_BY_USER, FETCH_RET, START_LOADING, END_LOADING } from '../actions/constants'

const rets = (state = { isLoading: true, rets: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_RETS:
        return {
          ...state,
          rets: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_RETS_BY_USER:
        return { ...state, rets: action.payload };

      case FETCH_RET:
        return { ...state, ret: action.payload };
      case ADD_NEW_RET:
        return { ...state, rets: [...state.rets, action.payload] };
      case UPDATE_RET:
        return { ...state, rets: state.rets.map((ret) => (ret._id === action.payload._id ? action.payload : ret)) };
      case DELETE_RET:
        return { ...state, rets: state.rets.filter((ret) => ret._id !== action.payload) };
      default:
        return state;
    }
  };

  export default rets
