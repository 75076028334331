import * as api from '../api/index'

import { ADD_NEW_LOCKUP, UPDATE_LOCKUP, DELETE_LOCKUP, FETCH_LOCKUPS_BY_USER, FETCH_LOCKUP, START_LOADING, END_LOADING } from './constants'


export const getLockup = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchLockup(id);
      dispatch({ type: FETCH_LOCKUP, payload: { product: data } });

    } catch (error) {
      console.log(error);
    }
  };


export const getLockupsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchLockupsByUser(searchQuery)
      
      dispatch({ type: FETCH_LOCKUPS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createLockup =(product, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addLockup(product)
        dispatch({ type: ADD_NEW_LOCKUP, payload: data })
        openSnackbar("Lockup added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateLockup =(id, product, openSnackbar) => async (dispatch) => {

   
    try {
      const { data } = await api.updateLockup(id, product)
      dispatch({ type: UPDATE_LOCKUP, payload: data })
      openSnackbar("Lockup updated successfully")
    } catch (error) {
        console.log(error)
    }
}

export const deleteLockup =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteLockup(id)

        dispatch({type: DELETE_LOCKUP, payload: id})
        openSnackbar("Lockup deleted successfully")
    } catch (error) {
        console.log(error)
    }
}