
const user = JSON.parse(localStorage.getItem('profile'))

export const initialState = {
    items: [
        {modelNumber: '', unitPrice: '', quantity: '', discount: '', unitCost: '', doNotOrder: false, lockup: false},
    ],
    total: 0,
    notes: '',
    rates: '',
    salesTax: 0.00,
    deliveryFee: 10.00,
    deliveryFeeOverride: false,
    invoiceNumber: Math.floor(Math.random() * 100000),
    status: '',
    type: 'Invoice',
    creator: '',
}
