import React, {useRef, useState, useEffect} from 'react';
import { useDispatch } from 'react-redux'
import styles from './Lockups.module.css'
// import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import { Button } from '@material-ui/core';
import { useSnackbar } from 'react-simple-snackbar'
import {DataGrid, GridToolbar, GridCellEditStopReasons} from "@mui/x-data-grid";
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import ProgressButton from 'react-progress-button'
import * as formData from 'form-data';
import { getLockupsByUser } from '../../actions/lockupActions'

import { deleteLockup, updateLockup } from '../../actions/lockupActions';
// import lockups from '../../lockups.json'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),

  },
}));


const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: '16px'
}
}));

const Lockups = ({ setOpen, setCurrentId, lockups }) => {

  // const sortedLockups = lockups.sort((a,b) => a.brand.toLowerCase().localeCompare(b.brand.toLowerCase()));
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const [uploadStatus, setUploadStatus] = useState(null);
  const user = JSON.parse(localStorage.getItem('profile'))
  const dispatch = useDispatch()

  const inputRef = useRef(null);

  const handleUploadButtonClick = () => {
    inputRef.current.click();
  }

  const discontinueLockup = async (params) => {
    await new Promise((resolve, reject) => {
        resolve(dispatch(updateLockup(params.row._id, ({...params.row, discontinued: true}), openSnackbar)))
      }).then(() => {
        dispatch(getLockupsByUser({ search: user?.result?._id || user.result.googleId }));
      })
  }

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (fileObj.name !== 'lockup_list.csv') {
      openSnackbar('Error: Please upload lockup_list.csv');
      setUploadStatus('error')
      // 👇️ reset file input
      event.target.value = null;
    } else {
      const form = new formData();
      console.log(fileObj);
      form.append('csvFile', fileObj);

      setUploadStatus('loading')
      axios.post(`${process.env.REACT_APP_API}/upload-csv-lockup`,
      form, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      })
      .then(res => {
        openSnackbar(res.data);
        setUploadStatus('success')
        dispatch(getLockupsByUser({ search: user?.result?._id || user.result.googleId }));
      })
    }
  };

  const formatDollarsToCents = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
        value = value.substring(0, value.indexOf('.') + 3);
    }
  
    return value ? Math.round(parseFloat(value) * 100) : 0;
  };
  
  const formatCentsToDollars = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, '');
    value = parseFloat(value);
    return value ? value / 100 : 0;
  };

  const displayAsDollars = function(params) {
    return dollar_formatter.format(formatCentsToDollars(params))
  }

  const processRowUpdate = React.useCallback(
    (newRow) => {
      // Make the HTTP request to save in the backend
      dispatch(updateLockup(newRow._id, newRow, openSnackbar))
      return newRow;
    }, []
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    openSnackbar(error);
  }, []);
  
  // Create our number formatter.
  const dollar_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
const columns = [
  {
    field: 'modelNumber',
    headerName: 'Model Number',
    width: 150,
    editable: true,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 150,
    editable: true,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    width: 150,
    editable: true,
    valueGetter: (params) => {
      if (!params.value) {
        return params.value;
      }
      const valueFormatted = displayAsDollars(params.value);
      return valueFormatted;
    },
    valueSetter: (params) => {
      let c = formatDollarsToCents(params.value)
      return { ...params.row,  cost: c};
    },
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 150,
    editable: true,
    valueGetter: (params) => {
      if (!params.value) {
        return params.value;
      }
      const valueFormatted = displayAsDollars(params.value);
      return valueFormatted;
    },
    valueSetter: (params) => {
      let p = formatDollarsToCents(params.value)
      return { ...params.row,  price: p};
    },
  },
  { field: 'actions', headerName: 'Actions', headerAlign: 'center', align: 'center', width: 250, renderCell: (params) => {
    return (
      <div style={{display: 'flex'}}>
        <Button
          style={{color: 'white', backgroundColor: 'red'}}
          onClick={() => dispatch(deleteLockup(params.row._id, openSnackbar))}
          
          variant="contained"
        >
          Delete
        </Button>
          

      </div>
      
    );
  } }
];


return (
  <div className={styles.pageLayout}>
    <Container style={{maxWidth: 1600}}>
      <div className={styles.buttons}>
      <h1 style={{paddingTop: 10}}>Lockups</h1>
        <button 
          // disabled={status === 'Paid' ? true : false}
          className={styles.btn} 
          onClick={() => setOpen((prev) => !prev)}> 
        Add Lockup
        </button>
          <input
            style={{display: 'none'}}
            ref={inputRef}
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
          <ProgressButton 
            // disabled={status === 'Paid' ? true : false}
            style={{width: 150}}
            state={uploadStatus}
            onClick={handleUploadButtonClick}> 
          Upload CSV
          </ProgressButton>
      </div>
      <div className={styles.gridLayout}>
            <DataGrid
              autoHeight
              rows={lockups}
              getRowId={(row) => row._id}
              getRowHeight={() => 'auto'}
              columns={columns}
              pageSize={20}
              components={{
                Toolbar: GridToolbar,
              }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
        </div>
    </Container>
  </div>
  
);
}

export default Lockups