 /* eslint-disable */
import React, { useState, useEffect} from 'react'
import Inventories from './Inventories'
import AddInventory from './AddInventory'
import { getInventoriesByUser } from '../../actions/inventoryActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'


const InventoryList = () => {

    const history = useHistory()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {inventories} = useSelector((state) => state.inventories)
    const isLoading = useSelector(state => state.inventories.isLoading)
    // const inventories = []

    
    // useEffect(() => {
    // }, [currentId, dispatch]);
    
//     useEffect(() => {
//         dispatch(getInventories(1));
//         // dispatch(getInventoriesByUser({userId : user?.result?._id}));
//         // dispatch(getInventoriesByUser({ search :user?.result?._id, tags: tags.join(',') }));
//     },[location]
// )

useEffect(() => {
    dispatch(getInventoriesByUser({ search: user?.result?._id || user.result.googleId }));
  },[location, dispatch])

  if(!user) {
    history.push('/login')
  }

  
  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

    return (
        <div>
            <AddInventory 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
            />
            <Inventories 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
                inventories={inventories}
            />
        </div>
    )
}

export default InventoryList

