 /* eslint-disable */
 import React, { useState, useEffect} from 'react';
 import moment from 'moment'
 import { useHistory } from 'react-router-dom'
 import { useLocation } from 'react-router-dom';
 import { makeStyles, useTheme, withStyles, alpha } from '@material-ui/core/styles';
 import PropTypes from 'prop-types';
 import Button from '@material-ui/core/Button';
 import Dialog from '@material-ui/core/Dialog';
 import MuiDialogTitle from '@material-ui/core/DialogTitle';
 import MuiDialogContent from '@material-ui/core/DialogContent';
 import MuiDialogActions from '@material-ui/core/DialogActions';
 import IconButton from '@material-ui/core/IconButton';
 import CloseIcon from '@material-ui/icons/Close';
 import Typography from '@material-ui/core/Typography';
 import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Checkbox } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
 import { useDispatch, useSelector } from 'react-redux'
 import { createRoute, updateRoute } from '../../actions/routesActions'
 import { getRoutesByUser } from '../../actions/routesActions';
 import { useSnackbar } from 'react-simple-snackbar'
 
 const styles = (theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(2),
     backgroundColor: '#1976D2',
     marginLeft: 0,
   },
   closeButton: {
     position: 'absolute',
     right: theme.spacing(1),
     top: theme.spacing(1),
     color: 'white',
   },
 });
 const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
  
    },
  }));
  
  function TablePaginationActions(props) {
  
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root} >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const useStyles2 = makeStyles(theme => ({
    table: {
      minWidth: 500,
      
    },
  
    tablecell: {
      fontSize: '16px'
  }
  }));
  
 
 const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose, ...other } = props;
   return (
     <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">{children}</Typography>
       {onClose ? (
         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
           <CloseIcon />
         </IconButton>
       ) : null}
     </MuiDialogTitle>
   );
 });
 
 const DialogContent = withStyles((theme) => ({
   root: {
     padding: theme.spacing(3),
   },
 }))(MuiDialogContent);
 
 const DialogActions = withStyles((theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(1),
   },
 }))(MuiDialogActions);

 const tableStyle = { width: 160, fontSize: 14, cursor: 'pointer', borderBottom: 'none',  padding: '8px', textAlign: 'center' }
    const headerStyle = { borderBottom: 'none', textAlign: 'center'}
 
 const AddRoute = ({ setOpen, open, currentId, setCurrentId }) => {
     const location = useLocation()
     const history = useHistory()
     const [routeData, setRouteData] = useState({ invoices: [], userId: []})
     const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
     const dispatch = useDispatch()
     const route = useSelector((state)=> currentId ? state.routes.routes.find((c) => c._id === currentId) : null)
     // eslint-disable-next-line 
     const [openSnackbar, closeSnackbar] = useSnackbar()
     const [selectedDate, setSelectedDate] = useState(null);
     const [selected, setSelected] = useState([]);
     const {invoices} = useSelector(state => state.invoices)
     const [rows, setRows] = useState(invoices);
     
     const classes = useStyles2();

    useEffect(() => {
        setRows(invoices)
    }, [invoices])

    useEffect(() => {
        let temp = [];
        for (var i = 0; i < selected.length; i++) {
            let n = invoices.find(invoice => invoice.invoiceNumber === selected[i]);
            temp.push(n)
        }
        setRouteData({...routeData, invoices: temp})
    }, [selected])

     const toCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rows.length);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openInvoice = (id) => {
        history.push(`/invoice/${id}`)
      }

    //  useEffect(() => {
    //    if(route) {
    //      setRouteData(route)
    //    }
    //  }, [route])
 
     useEffect(() => {
       setUser(JSON.parse(localStorage.getItem('profile')))
       // setRouteData({...routeData, userId: user?.result?._id})
     },[location])
 
 
     useEffect(() => {
       var checkId = user?.result?._id
       if(checkId !== undefined) {
         setRouteData({...routeData, userId: [checkId]})
       } else {
         setRouteData({...routeData, userId: [user?.result?.googleId]})
       }
       
     },[location])
 
     const handleSubmitRoute =(e)=> {
         e.preventDefault()
         if(currentId) {
           dispatch(updateRoute(currentId, routeData, openSnackbar))
         } else {
           dispatch(createRoute(routeData, openSnackbar))
         }
         
         handleClose()
     }
 
   const clear =() => {
     setCurrentId(null) 
     setRouteData({ invoices: [], userId: []})
   }

   const handleDateChange = (value) => {
    if (value !== null) {
      let val = moment(value).format('MM/DD/yyyy');
      setSelectedDate(val);
      const filteredRows = invoices.filter((row) => {
        if (moment(row.receivedByDate).format('MM/DD/yyyy').includes(val)) {
          return true;
        }
        return false;
      });
      setRows(filteredRows);
    } else {
      setRows(invoices);
    }
  }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.invoiceNumber);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, invoiceNumber) => {
        const selectedIndex = selected.indexOf(invoiceNumber);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, invoiceNumber);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        
        setSelected(newSelected);
      };

    const isSelected = (invoiceNumber) => {
      return selected.indexOf(invoiceNumber) !== -1;
    }
     
   const handleClose = () => {
     setOpen(false);
     setSelectedDate(null);
     setSelected([]);
     clear();
   };
 
   const inputStyle = {
     display: "block",
     padding: "1.4rem 0.75rem",
     width: "100%",
     fontSize: "0.8rem",
     lineHeight: 1.25,
     color: "#55595c",
     backgroundColor: "#fff",
     backgroundImage: "none",
     backgroundClip: "padding-box",
     borderTop: "0",
     borderRight: "0",
     borderBottom: "1px solid #eee",
     borderLeft: "0",
     borderRadius: "3px",
     transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
 }
 
 
   return (
     <div>
         <form >
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
             <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}}>
             {currentId? 'Edit Route' : 'Add New Route'}
             </DialogTitle>
             <DialogContent dividers>
 
 
             <Container style={{width: '100%', paddingBottom: '25px', border: 'none'}} >
                <div className={styles.toolBar}>
                    <Container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Filter By Date"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                        </MuiPickersUtilsProvider>
                    </Container>
                    </div>
                    <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label="custom pagination table">

                <TableHead>
                    <TableRow>
                    <TableCell style={headerStyle}>
                        <Checkbox
                            color="primary"
                            onChange={handleSelectAllClick}
                        />
                    </TableCell>
                        {/* <TableCell style={headerStyle}>Number</TableCell> */}
                        <TableCell style={headerStyle}>Client</TableCell>
                        <TableCell style={headerStyle}>Received Date</TableCell>
                        <TableCell style={headerStyle}>Due Date</TableCell>
                        <TableCell style={headerStyle}>Product List</TableCell>
                    </TableRow>
                    </TableHead>

                    <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, index) =>{
                        const isItemSelected = isSelected(row.invoiceNumber)
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow key={row._id} style={{cursor: 'pointer'}} >
                                <TableCell style={tableStyle}>
                                <Checkbox
                                    color="primary"
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    checked={isItemSelected}
                                    selected={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    onClick={(event) => handleClick(event, row.invoiceNumber)}
                                />
                                </TableCell>
                                {/* <TableCell style={tableStyle} onClick={() => openInvoice(row._id)}> {row.invoiceNumber} </TableCell> */}
                                <TableCell  style={tableStyle} onClick={() => openInvoice(row._id)} > {row.client.store} </TableCell>
                                <TableCell style={tableStyle} onClick={() => openInvoice(row._id)} > {moment(row.receivedByDate).format('MM/DD/yyyy')} </TableCell>
                                <TableCell style={tableStyle} onClick={() => openInvoice(row._id)} > {moment(row.dueDate).format('MM/DD/yyyy')} </TableCell>
                                <TableCell style={{...tableStyle, width: 150}} onClick={() => openInvoice(row._id)} >
                                {(row.items).map((item) => (
                                    <p>{item.modelNumber} : {item.quantity}</p>
                                ))}
                                </TableCell>
                                
                            </TableRow>
                        )

                    } )}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    {rows.length === 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell style={tableStyle} colSpan={6} > {`${invoices.length} invoices found, but no Received Date match.`} </TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                    <TableFooter>
                    <TableRow>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={6}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                    </TableFooter>
                </Table>
                </TableContainer>
                </Container>
 
             </DialogContent>
             <DialogActions>
             <Button  onClick={handleSubmitRoute}  variant="contained" style={{marginRight: '25px'}} >
                 Save Route
             </Button>
             </DialogActions>
       </Dialog>
         </form>
     </div>
   );
 }
 
 export default AddRoute