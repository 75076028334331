import * as api from '../api/index'

import { ADD_NEW_INVENTORY, UPDATE_INVENTORY, DELETE_INVENTORY, FETCH_INVENTORIES_BY_USER, FETCH_INVENTORY, START_LOADING, END_LOADING } from './constants'


export const getInventory = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchInventory(id);
      dispatch({ type: FETCH_INVENTORY, payload: { product: data } });

    } catch (error) {
      console.log(error);
    }
  };


export const getInventoriesByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchInventoriesByUser(searchQuery)
      
      dispatch({ type: FETCH_INVENTORIES_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createInventory =(product, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addInventory(product)
        dispatch({ type: ADD_NEW_INVENTORY, payload: data })
        openSnackbar("Inventory added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateInventory =(id, product, openSnackbar) => async (dispatch) => {

   
    try {
      const { data } = await api.updateInventory(id, product)
      dispatch({ type: UPDATE_INVENTORY, payload: data })
      openSnackbar("Inventory updated successfully")
    } catch (error) {
        console.log(error)
    }
}

export const deleteInventory =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteInventory(id)

        dispatch({type: DELETE_INVENTORY, payload: id})
        openSnackbar("Inventory deleted successfully")
    } catch (error) {
        console.log(error)
    }
}