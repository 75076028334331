
import { FETCH_ALL_WHITES, ADD_NEW_WHITE, UPDATE_WHITE, DELETE_WHITE, GET_WHITE, START_LOADING, END_LOADING, FETCH_WHITE_BY_USER } from '../actions/constants'

const whites = (state = { isLoading: true, whites: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALL_WHITES:
        return {
          ...state,
          whites: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_WHITE_BY_USER:
        return { ...state, whites: action.payload };

      case GET_WHITE:
        return { ...state, white: action.payload };
      case ADD_NEW_WHITE:
        return { ...state, whites: [...state.whites, action.payload] };
      case UPDATE_WHITE:
        return { ...state, whites: state.whites.map((white) => (white._id === action.payload._id ? action.payload : white)) };
      case DELETE_WHITE:
        return { ...state, whites: state.whites.filter((white) => white._id !== action.payload) };
      default:
        return state;
    }
  };

  export default whites



//   const whites =( state = { whites: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, whites: action.payload }

//         case GET_WHITE:
//             return { ...state, white: action.payload }

//         case ADD_NEW:
//             return { ...state, whites: [...state.whites, action.payload] }

//         case UPDATE:
//             return { ...state, whites: state.whites.map((white) => white._id === action.payload ? action.payload : white) }
        
//         case DELETE: 
//         return {...state, whites: state.whites.filter((white) => white._id !== action.payload)}
        
//         default:
//             return state;
//     }
// }

// export default whites
