import React, {setState, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import styles from "./Routes.module.css";
import cardStyles from "./Cards.module.css";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useLocation } from 'react-router-dom';
import { getRoutesByUser } from '../../actions/routesActions';
import { getInvoicesByUser } from '../../actions/invoiceActions';
import NoData from '../svgIcons/NoData';
import Spinner from '../Spinner/Spinner'
import { useSnackbar } from 'react-simple-snackbar'
import { deleteRoute } from '../../actions/routesActions';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddRoute from './AddRoute'
import DisplayRoute from './DisplayRoute'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),

  },
}));

function TablePaginationActions(props) {

  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root} >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: 500,
    
  },

  tablecell: {
    fontSize: '16px'
}
}));

const tableStyle = { width: 160, fontSize: 14, cursor: 'pointer', borderBottom: 'none',  padding: '8px', textAlign: 'center' }
const headerStyle = { borderBottom: 'none', textAlign: 'center'}


const Routes = () => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [displayOpen, setDisplayOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null);
  const dispatch = useDispatch()
  const {invoices} = useSelector(state => state.invoices);
  const {routes} = useSelector(state => state.routes);
  const user = JSON.parse(localStorage.getItem('profile'))
  const [cards, setCards] = useState(routes);
  const isLoading = useSelector(state => state.routes.isLoading)
        // eslint-disable-next-line 
  const [openSnackbar, closeSnackbar] = useSnackbar()
  // const [selectedDate, setSelectedDate] = useState(() => {
  //   return moment().format('MM/DD/yyyy')
  // });
  const [selectedDate, setSelectedDate] = useState(null);
    useEffect(() => {
        // get by user
        // dispatch(getInvoicesByUser({search: user?.result._id || user?.result?.googleId}));
        // get white or invoice
        dispatch(getInvoicesByUser({search: false}));
      dispatch(getRoutesByUser({ search: user?.result?._id || user?.result?.googleId}));
      // eslint-disable-next-line
    },[location, dispatch])

    useEffect(() => {
      console.log(routes);
      const filteredRows = routes.filter((row) => {
        if (moment(row.invoices[0].receivedByDate).format('MM/DD/yyyy').includes(selectedDate)) {
          return true;
        }
        return false;
      });
      setCards(filteredRows)
    }, [routes, selectedDate])

    console.log(routes);
    
   const toCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

  const handleDateChange = (value) => {
    if (value !== null) {
      let val = moment(value).format('MM/DD/yyyy');
      setSelectedDate(val);
      const filteredRows = routes.filter((row) => {
        if (moment(row.invoices[0].receivedByDate).format('MM/DD/yyyy').includes(val)) {
          return true;
        }
        return false;
      });
      setCards(filteredRows);
    } else {
      setCards(routes);
    }
  }

  const handleEdit = (selectedRoute) => {
    
    setOpen((prevState) => !prevState)
    setCurrentId(selectedRoute)

  }

  const handleDisplayOpen = (selectedRoute, routeIndex) => {
    setDisplayOpen((prevState) => !prevState)
    setCurrentId(selectedRoute);
    setCurrentIndex(routeIndex)
  }

  if(!user) {
    history.push('/login')
  }

  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

  if(cards.length === 0 && routes.length === 0) {
    return (
      <div>
        <AddRoute 
          open={open} 
          setOpen={setOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
      />
      <DisplayRoute
          open={displayOpen} 
          setOpen={setDisplayOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
      />
      <Container style={{width: '85%', paddingTop: '70px', paddingBottom: '50px', border: 'none'}} >
        <div className={styles.toolBar}>
          <Container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Filter By Receive Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Container>
        </div>
        <div className={styles.titleContainer}>
        <h1 style={{paddingTop: 10, paddingLeft: 25}}>Routes</h1>
        <div className={styles.buttons}>
          <button 
            // disabled={status === 'Paid' ? true : false}
            className={styles.btn} 
            onClick={() => setOpen((prev) => !prev)}> 
            Add Route
          </button>
        </div>
      </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px'}}>
        <NoData />
        <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>{`No routes found, click "Add Route" to add a route.`}</p>
        </div>
      </Container>
    </div>
    );
  }

  if(cards.length === 0 && routes.length > 0) {
    return (
    
      <div>
        <AddRoute 
          open={open} 
          setOpen={setOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
      />
      <DisplayRoute
          open={displayOpen} 
          setOpen={setDisplayOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
      />
      <Container style={{width: '85%', paddingTop: '70px', paddingBottom: '50px', border: 'none'}} >
        <div className={styles.toolBar}>
          <Container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Filter By Receive"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Container>
        </div>
        <div className={styles.titleContainer}>
        <h1 style={{paddingTop: 10, paddingLeft: 25}}>Routes</h1>
        <div className={styles.buttons}>
          <button 
            // disabled={status === 'Paid' ? true : false}
            className={styles.btn} 
            onClick={() => setOpen((prev) => !prev)}> 
            Add Route
          </button>
        </div>
      </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px'}}>
        <NoData />
        <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>{`${routes.length} routes found, but no Received Date match.`}</p>
        </div>
      </Container>
    </div>
    );
  }
  
  return (
    
    <div>
      <AddRoute 
          open={open} 
          setOpen={setOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
      />
      <DisplayRoute
          open={displayOpen} 
          setOpen={setDisplayOpen}
          currentId={currentId}
          setCurrentId={setCurrentId}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
      />
    <Container style={{width: '85%', paddingTop: '70px', paddingBottom: '50px', border: 'none'}} >
    <div className={styles.toolBar}>
          <Container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Filter By Receive Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            
          </Container>
      </div>
      <div className={styles.titleContainer}>
        <h1 style={{paddingTop: 10, paddingLeft: 25}}>Routes</h1>
        <div className={styles.buttons}>
          <button 
            // disabled={status === 'Paid' ? true : false}
            className={styles.btn} 
            onClick={() => setOpen((prev) => !prev)}> 
            Add Route
          </button>
        </div>
      </div>
      <section className={cardStyles.stat}>
        <ul className={cardStyles.autoGrid}>
          {(cards).map((card, index) =>{
            return (
              <li key={index} className={cardStyles.listItem}>
                  <div onClick={() => handleDisplayOpen(card._id, (index + 1))}>
                      <h1>Route {index + 1}</h1>
                      <h2>Delivery Date: {moment(card.invoices[0].receivedByDate).format('MM/DD/yyyy')}</h2>
                      {(card.invoices).map((invoice) => {
                        return (
                          <div>
                          <p className={cardStyles.storeName}>{invoice.client.store}</p>
                          {/* <p>Store: {invoice.client.store}</p> */}
                          {(invoice.items).map((item) => {
                            return (
                              <p className={cardStyles.itemInfo}>&nbsp; {item.modelNumber} : {item.quantity}</p>
                            )
                          })}
                          </div>
                        )
                      })}
                  </div>
                  <div>
                  <IconButton onClick={() => handleEdit(card._id)}>
                    <BorderColorIcon style={{width: '20px', height: '20px'}} />
                  </IconButton>
                  <IconButton onClick={() => dispatch(deleteRoute(card._id, openSnackbar))}>
                    <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}} />
                  </IconButton>
                  </div>
              </li>
            )
          } )}
          
        </ul>
        </section>
    </Container>
  </div>
  );
}

export default Routes