import * as api from '../api/index'

import { ADD_NEW_RET, UPDATE_RET, DELETE_RET, FETCH_RETS_BY_USER, FETCH_RET, START_LOADING, END_LOADING } from './constants'


export const getRet = (date) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchRet(date);
      dispatch({ type: FETCH_RET, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error);
    }
  };


export const getRetsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchRetsByUser(searchQuery)
      
      dispatch({ type: FETCH_RETS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createRet =(ret, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addRet(ret)
        dispatch({ type: ADD_NEW_RET, payload: data })
        openSnackbar("Return added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateRet =(date, ret, openSnackbar) => async (dispatch) => {
    try {
      const { data } = await api.updateRet(date, ret)
      dispatch({ type: UPDATE_RET, payload: data })
      openSnackbar("Return updated successfully")
    } catch (error) {
        console.log(error)
    }
}

export const deleteRet =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteRet(id)

        dispatch({type: DELETE_RET, payload: id})
        openSnackbar("Ret deleted successfully")
    } catch (error) {
        console.log(error)
    }
}