import React from 'react'
import styles from './Home.module.css'

const Home = () => {
   
    return (
        <div className={styles.pageContainer}>
            
            <section className={styles.hero}>
                <h2>New York's Largest Full Line Distributor</h2>
                <h1>Wholesale U.S.A.</h1>
                <h2>(516) 799-6404</h2>
                <h3>
                    <p>Massapequa, NY 11758</p>
                    <p>wholesaleusa@gmail.com</p>
                </h3>
                <div className={styles.imgContainer}>
                    <img src="https://res.cloudinary.com/almpo/image/upload/v1637241441/special/banner_izy4xm.png" alt="invoicing-app"/>
                </div>
            </section>
        </div>
    )
}

export default Home
