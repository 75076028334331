 /* eslint-disable */
import React, { useState, useEffect} from 'react'
import Lockups from './Lockups'
import AddLockup from './AddLockup'
import { getLockupsByUser } from '../../actions/lockupActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'


const LockupList = () => {

    const history = useHistory()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {lockups} = useSelector((state) => state.lockups)
    const isLoading = useSelector(state => state.lockups.isLoading)
    // const lockups = []

    
    // useEffect(() => {
    // }, [currentId, dispatch]);
    
//     useEffect(() => {
//         dispatch(getLockups(1));
//         // dispatch(getLockupsByUser({userId : user?.result?._id}));
//         // dispatch(getLockupsByUser({ search :user?.result?._id, tags: tags.join(',') }));
//     },[location]
// )

useEffect(() => {
    dispatch(getLockupsByUser({ search: user?.result?._id || user.result.googleId }));
  },[location, dispatch])

  if(!user) {
    history.push('/login')
  }

  
  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

    return (
        <div>
            <AddLockup 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
            />
            <Lockups 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
                lockups={lockups}
            />
        </div>
    )
}

export default LockupList

