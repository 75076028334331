import React, { useState, useEffect } from "react";
import styles from "./White.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toCommas } from "../../utils/utils";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, alpha } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { initialState } from "../../initialState";
import currencies from "../../currencies.json";
import {
  createWhite,
  getWhite,
  updateWhite,
} from "../../actions/whiteActions";
import { getClientsByUser } from "../../actions/clientActions";
import { getProductsByUser } from "../../actions/productActions";
import { getLockupsByUser } from "../../actions/lockupActions";
import AddClient from "./AddClient";
import WhiteType from "./WhiteType";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { set } from "date-fns";
import {Checkbox, FormGroup, FormControlLabel} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const White = () => {
  const location = useLocation();
  const [whiteData, setWhiteData] = useState(initialState);
  const [product, setProduct] = useState(null)
  const [lockup, setLockup] = useState(null)
  const [rates, setRates] = useState(0.00);
  const [deliveryFee, setDeliveryFee] = useState(10.00);
  const [deliveryFeeOverride, setDeliveryFeeOverride] = useState(false);
  const [salesTax, setSalesTax] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedRbDate, setSelectedRbDate] = useState(
    moment().add(1, 'days')
  );
  const [selectedDate, setSelectedDate] = useState(
    moment(selectedRbDate).add(1, 'days')
  );
  const [client, setClient] = useState(null);
  const [type, setType] = useState("White");
  const [status, setStatus] = useState("");
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const products = useSelector((state) => state.products.products);
  const lockups = useSelector((state) => state.lockups.lockups);
  const { white } = useSelector((state) => state.whites);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    getTotalCount();
    // eslint-disable-next-line
  }, [location]);

  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/whites/count?searchQuery=${user?.result?._id}`
      );
      //   console.log(response.data);
      //Get total count of white from the server and increment by one to serialized numbering of white
      setWhiteData({
        ...whiteData,
        invoiceNumber: (Number(response.data) + 1).toString().padStart(3, "0"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getWhite(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(
      getClientsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    dispatch(
      getProductsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    dispatch(
      getLockupsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (white) {
      //Automatically set the default white values as the ones in the white to be updated
      setWhiteData(white);
      setRates(white.rates);
      setClient(white.client);
      setType(white.type);
      setStatus(white.status);
      setSelectedDate(white.dueDate);
      setSelectedRbDate(white.receivedByDate);
    }
  }, [white]);

  useEffect(() => {
    if (type === "Receipt") {
      setStatus("Paid");
    } else {
      setStatus("Unpaid");
    }
  }, [type]);

  const formatDollarsToCents = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
        value = value.substring(0, value.indexOf('.') + 3);
    }

    return value ? Math.round(parseFloat(value) * 100) : 0;
};

const formatCentsToDollars = function (value) {
    value = `${value}`.replace(/[^\d.-]/g, '');
    value = parseFloat(value);
    return value ? value / 100 : 0;
};

// Create our number formatter.
const dollar_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.label,
  };

  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.store,
  };

  const productsProps = {
    options: products,
    getOptionLabel: (option) => option.modelNumber,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRbDateChange = (date) => {
    setSelectedRbDate(date);
    let days = client ? client.term : 1;
    let tomorrow = moment(date).add(days, 'days');
    setSelectedDate(tomorrow);
  };

  const handleRates = (e) => {
    setRates(e.target.value);
    setWhiteData((prevState) => ({ ...prevState, tax: e.target.value }));
  };

  const handleDeliveryFeeChange = (e) => {
    let value = 0
    if (e.target.value && e.target.value !== '') {
      value = parseInt(e.target.value)
    }
    setDeliveryFee(value)
    setWhiteData((prevState) => ({ ...prevState, deliveryFee: value}));
  }

  const handleDeliveryFeeOverride = (e) => {
    setDeliveryFeeOverride(e.target.checked);
    setWhiteData((prevState) => ({ ...prevState, deliveryFeeOverride: e.target.checked}));
  }

  const handleDoNotOrderClick = (index, e) => {
    console.log(e.value);
    const values = [...whiteData.items];
    values[index][e.target.name] = e.target.checked;
    console.log(values);
    setWhiteData({...whiteData, items: values})
  }

  const handleLockupClick = (index, e) => {
    const values = [...whiteData.items];
    values[index][e.target.name] = e.target.checked;
    if (e.target.checked) {
      if (!lockup || (lockup && values[index]['modelNumber'] !== lockup.modelNumber)) {
        lockups.find(obj => {
          if (obj.modelNumber === product.modelNumber) {
            console.log("finding lockup");
            values[index]['unitPrice'] = formatCentsToDollars(obj.price);
            values[index]['unitCost'] = formatCentsToDollars(obj.cost);
            setLockup(obj);
            return true;
          } else {
            return false;
          }
        })
      } else {
        console.log("Loaded from lockup");
        values[index]['unitPrice'] = formatCentsToDollars(lockup.price);
        values[index]['unitCost'] = formatCentsToDollars(lockup.cost);
      }
    } else {
      values[index]['unitPrice'] = formatCentsToDollars(product.price);
      values[index]['unitCost'] = formatCentsToDollars(product.cost);
    }
    setWhiteData({...whiteData, items: values})
  }

  const handleProductChange = (index, e, value) => {
    setProduct(value);
    const values = [...whiteData.items];
    if (value !== null) {
      values[index]['modelNumber'] = value.modelNumber;
      values[index]['unitPrice'] = formatCentsToDollars(value.price);
      values[index]['unitCost'] = formatCentsToDollars(value.cost);
    } else {
      values[index]['modelNumber'] = '';
      values[index]['unitPrice'] = '';
      values[index]['unitCost'] = '';
    }
    setWhiteData({ ...whiteData, items: values });

  }

  const handleClientChange = (value) => {
    setClient(value);
    if (value) {
      let tempDueDate = moment(selectedRbDate).add(value.term, 'days');
      setSelectedDate(tempDueDate); 
    } else {
      let tempDueDate = moment(selectedRbDate).add(1, 'days');
      setSelectedDate(tempDueDate);
    }

  }

  // console.log(whiteData)
  // Change handler for dynamically added input field
  const handleChange = (index, e) => {
    const values = [...whiteData.items];
    values[index][e.target.name] = e.target.value;
    console.log(values);
    setWhiteData({ ...whiteData, items: values });
  };

  const handlePriceChange = (index, e) => {
    const values = [...whiteData.items];
    values[index][e.target.name] = e.target.value;
  }

  useEffect(() => {
    //Get the subtotal
    const subTotal = () => {
      var arr = document.getElementsByName("amount");
      var subtotal = 0;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value) {
          subtotal += +arr[i].value;
        }
        // document.getElementById("subtotal").value = subtotal;
        setSubTotal(subtotal);
      }
      if (!deliveryFeeOverride) {
        if (subtotal < 2000) {
          setDeliveryFee(10.00)
        } else {
          setDeliveryFee(0.00)
        }
      }
    };

    subTotal();
  }, [whiteData]);

  // useEffect(() => {
  //   if (client) {
  //     let tempDueDate = moment(selectedRbDate).add(client.term, 'days');
  //     setSelectedDate(tempDueDate); 
  //   } else {
  //     let tempDueDate = moment(selectedRbDate).add(1, 'days');
  //     setSelectedDate(tempDueDate);
  //   }
  // }, [client])

  useEffect(() => {
    const total = () => {
      //Tax rate is calculated as (input / 100 ) * subtotal + subtotal
      
      //VAT is calculated as tax rates /100 * subtotal
      let cents = formatDollarsToCents(subTotal);
      let totalTaxCents = (rates / 100) * cents;
      let totalTax = parseFloat(formatCentsToDollars(totalTaxCents).toFixed(2));
      const overallSum = totalTax + subTotal + deliveryFee;
      setSalesTax(totalTax);
      setTotal(overallSum);
    };
    total();
  }, [whiteData, rates, subTotal, deliveryFee]);

  const handleAddField = (e) => {
    e.preventDefault();
    setWhiteData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        { modelNumber: "", unitPrice: "", quantity: "", discount: "", amount: "", unitCost: "", doNotOrder: false, lockup: false },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    const values = whiteData.items;
    values.splice(index, 1);
    setWhiteData((prevState) => ({ ...prevState, values }));
    // console.log(values)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (white) {
      dispatch(
        updateWhite(white._id, {
          ...whiteData,
          subTotal: subTotal,
          total: total,
          salesTax: salesTax,
          rates: rates,
          dueDate: selectedDate,
          receivedByDate: selectedRbDate,
          client,
          type: type,
          status: status,
          deliveryFee: deliveryFee,
          deliveryFeeOverride: deliveryFeeOverride
        })
      );
      history.push(`/white/${white._id}`);
    } else {
      dispatch(
        createWhite(
          {
            ...whiteData,
            subTotal: subTotal,
            total: total,
            salesTax: salesTax,
            rates: rates,
            dueDate: selectedDate,
            receivedByDate: selectedRbDate,
            invoiceNumber: `${
              whiteData.invoiceNumber < 100
                ? Number(whiteData.invoiceNumber).toString().padStart(3, "0")
                : Number(whiteData.invoiceNumber)
            }`,
            deliveryFee: deliveryFee,
            deliveryFeeOverride: deliveryFeeOverride,
            client,
            type: type,
            status: status,
            paymentRecords: [],
            creator: [user?.result?._id || user?.result?.googleId],
          },
          history
        )
      );
    }

    // setWhiteData(initialState)
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  if (!user) {
    history.push("/login");
  }

  return (
    <div className={styles.invoiceLayout}>
      <form onSubmit={handleSubmit} className="mu-form">
        <AddClient setOpen={setOpen} open={open} />
        <Container className={classes.headerContainer}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
            </Grid>
            <Grid item>
              <WhiteType type={type} setType={setType} />
              <div
                style={{
                  visibility: 'hidden',
                  marginTop: "15px",
                  width: "100px",
                  padding: "8px",
                  display: "inline-block",
                  backgroundColor: "#f4f4f4",
                  outline: "0px solid transparent",
                }}
                contenteditable="true"
                onInput={(e) =>
                  setWhiteData({
                    ...whiteData,
                    invoiceNumber: e.currentTarget.textContent,
                  })
                }
              >
                <span
                  style={{ width: "40px", color: "black", padding: "15px" }}
                  contenteditable="false"
                >
                  {" "}
                  {whiteData.invoiceNumber}
                </span>
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px" }}
          >
            <Grid item style={{ width: "50%" }}>
              <Container>
                <Typography
                  variant="overline"
                  style={{ color: "gray", paddingRight: "3px" }}
                  gutterBottom
                >
                  Bill to
                </Typography>

                {client && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      {client.store}
                    </Typography>
                    <Typography variant="body2">{client.email}</Typography>
                    <Typography variant="body2">{client.cellPhone}</Typography>
                    <Typography variant="body2">{client.address}</Typography>
                    <Button
                      color="primary"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => handleClientChange(null)}
                    >
                      Change
                    </Button>
                  </>
                )}
                <div
                  style={client ? { display: "none" } : { display: "block" }}
                >
                  <Autocomplete
                    {...clientsProps}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={!white && true}
                        label="Select Customer"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    value={clients?.store}
                    onChange={(event, value) => handleClientChange(value)}
                  />
                </div>
                {!client && (
                  <>
                    <Grid item style={{ paddingBottom: "10px" }}>
                      <Chip
                        avatar={<Avatar>+</Avatar>}
                        label="New Customer"
                        onClick={() => setOpen(true)}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: type === "Receipt" ? "green" : "red" }}
              >
                {type === "Receipt" ? "Paid" : "Unpaid"}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Date
              </Typography>
              <Typography variant="body2" gutterBottom>
              {selectedRbDate
                  ? moment(selectedRbDate).format("MMM Do YYYY")
                  : "27th Sep 2021"}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Due Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedDate
                  ? moment(selectedDate).format("MMM Do YYYY")
                  : "27th Sep 2021"}
              </Typography>
              <Typography variant="overline" gutterBottom>
                Amount
              </Typography>
              <Typography variant="h6" gutterBottom>
                ${toCommas(total)}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div>
          <TableContainer component={Paper} className="tb-container">
            <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>#</TableCell>
                  <TableCell>Cost ($)</TableCell>
                  <TableCell>Price ($)</TableCell>
                  {/* <TableCell>Disc (%)</TableCell> */}
                  <TableCell>Amount</TableCell>
                  <TableCell>Inv.</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {whiteData.items.map((itemField, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row" style={{ width: "40%" }}>
                      {" "}
                      {itemField.modelNumber && (
                        <>
                            <Typography variant="subtitle1" gutterBottom>
                                {itemField.modelNumber}
                            </Typography>
                        </>
                        )}
                        <div style={itemField.modelNumber ? { display: "none" } : { display: "block" }}>
                        <Autocomplete
                            options={products.sort((a, b) => {
                            if (
                                a.hasOwnProperty("brand") &&
                                b.hasOwnProperty("brand")
                            ) {
                                return -b.brand.localeCompare(a.brand);
                            }
                            })}
                            groupBy={(option) => option.brand}
                            getOptionLabel={(option) => option.modelNumber || ""}
                            PaperComponent={CustomPaper}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                name="product"
                                label="Select Product"
                                margin="normal"
                                variant="outlined"
                            />
                            )}
                            value={itemField.modelNumber}
                            onChange={(e, value) => handleProductChange(index, e, value)}
                        />
                        </div>
                      {" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="quantity"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.quantity}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                    {" "}
                      <FormGroup>
                        <FormControlLabel control={
                          <Checkbox
                            name="lockup"
                            checked={itemField.lockup}
                              color="primary"
                              onChange={(e) => handleLockupClick(index, e)}
                          />} label="" />
                      </FormGroup>{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="unitCost"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.unitCost}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="unitPrice"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.unitPrice}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    {/* <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="discount"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.discount}
                        placeholder="0"
                      />{" "}
                    </TableCell> */}
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="amount"
                        onChange={(e) => handleChange(index, e)}
                        value={
                          (itemField.quantity * itemField.unitPrice -
                          (itemField.quantity *
                            itemField.unitPrice *
                            itemField.discount) /
                            100).toFixed(2)
                        }
                        disabled
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                    {" "}
                      <FormGroup>
                        <FormControlLabel control={
                          <Checkbox
                            name="doNotOrder"
                            checked={itemField.doNotOrder}
                              color="primary"
                              onChange={(e) => handleDoNotOrderClick(index, e)}
                          />} label="" />
                      </FormGroup>{" "}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleRemoveField(index)}>
                        <DeleteOutlineRoundedIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.addButton}>
            <button onClick={handleAddField}>+</button>
          </div>
        </div>

        <div className={styles.invoiceSummary}>
          <div className={styles.summary}>White Summary</div>
          <div className={styles.summaryItem}>
            <p>Sub total:</p>
            <h4>${(subTotal).toFixed(2)}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>Sales Tax:</p>
            <h4>${(salesTax).toFixed(2)}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>Delivery Fee:</p>
            <h4>${(deliveryFee).toFixed(2)}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>Total</p>
            <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>
               ${toCommas(total.toFixed(2))}
            </h4>
          </div>
        </div>

        <div className={styles.toolBar}>
          <Container>
            <Grid container>
              <Grid item style={{ marginTop: "16px", marginRight: 10 }}>
                <TextField
                  type="text"
                  step="any"
                  name="rates"
                  id="rates"
                  value={rates}
                  onChange={handleRates}
                  placeholder="e.g 10"
                  label="Tax Rates(%)"
                />
              </Grid>
              <Grid item style={{ marginTop: '-26px', marginRight: 10 }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                            color="primary"
                            onChange={handleDeliveryFeeOverride}
                        />} label="Fee Override" />
                </FormGroup>
                <TextField
                  type="text"
                  step="any"
                  name="delivery"
                  id="delivery"
                  value={deliveryFee}
                  onChange={handleDeliveryFeeChange}
                  label="Delivery Fee ($)"
                />
              </Grid>
              <Grid item style={{ marginRight: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Received By Date"
                    format="MM/dd/yyyy"
                    value={selectedRbDate}
                    onChange={handleRbDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ marginRight: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Due date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.note}>
          <h4>Note/Payment Info</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder="Provide additional details or terms of service"
            onChange={(e) =>
              setWhiteData({ ...whiteData, notes: e.target.value })
            }
            value={whiteData.notes}
          />
        </div>

        {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        <Grid container justifyContent="center">
          <Button
            id="save-white"
            variant="contained"
            style={{ justifyContentContent: "center" }}
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save and Continue
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default White;
