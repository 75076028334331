
import { ALL_LOCKUPS, ADD_NEW_LOCKUP, UPDATE_LOCKUP, DELETE_LOCKUP, FETCH_LOCKUPS_BY_USER, FETCH_LOCKUP, START_LOADING, END_LOADING } from '../actions/constants'

const lockups = (state = { isLoading: true, lockups: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_LOCKUPS:
        return {
          ...state,
          lockups: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_LOCKUPS_BY_USER:
        return { ...state, lockups: action.payload };

      case FETCH_LOCKUP:
        return { ...state, lockup: action.payload.lockup };
      case ADD_NEW_LOCKUP:
        return { ...state, lockups: [...state.lockups, action.payload] };
      case UPDATE_LOCKUP:
        return { ...state, lockups: state.lockups.map((lockup) => (lockup._id === action.payload._id ? action.payload : lockup)) };
      case DELETE_LOCKUP:
        return { ...state, lockups: state.lockups.filter((lockup) => lockup._id !== action.payload) };
      default:
        return state;
    }
  };

  export default lockups




//   const lockups =(lockups =[], action) => {
//     switch (action.type) {
//         case ALL_LOCKUPS:
//             return action.payload

//         case FETCH_LOCKUPS_BY_USER:
//             return action.payload

//         case ADD_NEW_LOCKUP:
//             return [...lockups, action.payload]

//         case UPDATE_LOCKUP:
//             return lockups.map((lockup) => lockup._id === action.payload ? action.payload : lockup)
        
//         case DELETE_LOCKUP: 
//         return lockups.filter((lockup) => lockup._id !== action.payload)
        
//         default:
//             return lockups;
//     }
// }

// export default lockups
