import * as api from '../api/index'

import { ADD_NEW_WHITE, UPDATE_WHITE, DELETE_WHITE, GET_WHITE, FETCH_WHITE_BY_USER, START_LOADING, END_LOADING } from './constants'

// export const getWhites = () => async (dispatch)=> {
//     try {
//         const { data } = await api.fetchWhites()
//         dispatch({ type: FETCH_ALL, payload: data })
//     } catch (error) {
//         console.log(error)
//     }
// }

export const getWhitesByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchWhitesByUser(searchQuery)
      dispatch({ type: FETCH_WHITE_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const getWhite = (id) => async (dispatch)=> {

    const user = JSON.parse(localStorage.getItem('profile'))

    try {
        const { data } = await api.fetchWhite(id)
        const businessDetails = await api.fetchProfilesByUser({search: user?.result?._id || user?.result?.googleId})
        const whiteData = {...data, businessDetails}
        // console.log(whiteData)
        dispatch({ type: GET_WHITE, payload: whiteData  })
    } catch (error) {
        console.log(error.response)
    }
}

export const createWhite =(white, history) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.addWhite(white)
        dispatch({ type: ADD_NEW_WHITE, payload: data })
        history.push(`/white/${data._id}`)
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const updateWhite =(id, white) => async (dispatch) => {

    try {
        const { data } = await api.updateWhite(id, white)
        dispatch({ type: UPDATE_WHITE, payload: data })
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteWhite =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteWhite(id)

        dispatch({type: DELETE_WHITE, payload: id})
        openSnackbar("White deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}