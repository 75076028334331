import { combineReducers } from 'redux'

import invoices from './invoices'
import whites from './whites'
import clients from './clients'
import products from './products'
import lockups from './lockups'
import inventories from './inventories'
import auth from './auth'
import profiles from './profiles'
import routes from './routes'
import returns from './returns'

export default combineReducers({ invoices, whites, clients, products, lockups, inventories, routes, returns, auth, profiles })