
export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"

export const FETCH_ALL_WHITES = "FETCH_ALL"
export const ADD_NEW_WHITE = "ADD_NEW"
export const UPDATE_WHITE = "UPDATE"
export const DELETE_WHITE = "DELETE"
export const GET_WHITE = "GET_WHITE"
export const FETCH_WHITE_BY_USER = "FETCH_WHITE_BY_USER"

export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_CLIENTS_BY_USER';

export const ALL_PRODUCTS = "ALL_PRODUCTS"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
export const FETCH_PRODUCTS_BY_USER = 'FETCH_PRODUCTS_BY_USER';

export const ALL_RETS = "ALL_RETS"
export const UPDATE_RET = "UPDATE_RET"
export const DELETE_RET = "DELETE_RET"
export const ADD_NEW_RET = "ADD_NEW_RET"
export const FETCH_RETS_BY_USER = 'FETCH_RETS_BY_USER';

export const ALL_LOCKUPS = "ALL_LOCKUPS"
export const UPDATE_LOCKUP = "UPDATE_LOCKUP"
export const DELETE_LOCKUP = "DELETE_LOCKUP"
export const ADD_NEW_LOCKUP = "ADD_NEW_LOCKUP"
export const FETCH_LOCKUPS_BY_USER = 'FETCH_LOCKUPS_BY_USER';

export const ALL_INVENTORIES = "ALL_INVENTORIES"
export const UPDATE_INVENTORY = "UPDATE_INVENTORY"
export const DELETE_INVENTORY = "DELETE_INVENTORY"
export const ADD_NEW_INVENTORY = "ADD_NEW_INVENTORY"
export const FETCH_INVENTORIES_BY_USER = 'FETCH_INVENTORIES_BY_USER';

export const ALL_ROUTES = "ALL_ROUTES"
export const UPDATE_ROUTE = "UPDATE_ROUTE"
export const DELETE_ROUTE = "DELETE_ROUTE"
export const ADD_NEW_ROUTE = "ADD_NEW_ROUTE"
export const FETCH_ROUTES_BY_USER = 'FETCH_ROUTES_BY_USER';

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"
export const FETCH_PRODUCT = "FETCH_PRODUCT"
export const FETCH_RET = "FETCH_RET"
export const FETCH_LOCKUP = "FETCH_LOCKUP"
export const FETCH_INVENTORY = "FETCH_INVENTORY"
export const FETCH_ROUTE = "FETCH_ROUTE"
export const FETCH_ORDER_SHEET = "FETCH_ORDER_SHEET"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';