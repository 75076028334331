
import { ALL_ROUTES, ADD_NEW_ROUTE, UPDATE_ROUTE, DELETE_ROUTE, FETCH_ROUTES_BY_USER, FETCH_ROUTE, START_LOADING, END_LOADING } from '../actions/constants'

const routes = (state = { isLoading: true, routes: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_ROUTES:
        return {
          ...state,
          routes: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_ROUTES_BY_USER:
        return { ...state, routes: action.payload };

      case FETCH_ROUTE:
        return { ...state, route: action.payload.route };
      case ADD_NEW_ROUTE:
        return { ...state, routes: [...state.routes, action.payload] };
      case UPDATE_ROUTE:
        return { ...state, routes: state.routes.map((route) => (route._id === action.payload._id ? action.payload : route)) };
      case DELETE_ROUTE:
        return { ...state, routes: state.routes.filter((route) => route._id !== action.payload) };
      default:
        return state;
    }
  };

  export default routes