import axios from 'axios'

// const API = axios.create({ baseURL: 'http://localhost:5000'})
const API = axios.create({ baseURL: process.env.REACT_APP_API})

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }
    return req
})

// export const fetchInvoices =() => API.get('/invoices')
export const fetchInvoice =(id) => API.get(`/invoices/${id}`)
export const addInvoice =( invoice ) => API.post('/invoices', invoice)
export const updateInvoice = (id, updatedInvoice) => API.patch(`/invoices/${id}`, updatedInvoice)
export const deleteInvoice =(id) => API.delete(`/invoices/${id}`)
export const fetchInvoicesByUser = (searchQuery) => API.get(`/invoices?searchQuery=${searchQuery.search}`);

export const fetchWhite =(id) => API.get(`/whites/${id}`)
export const addWhite =( white ) => API.post('/whites', white)
export const updateWhite = (id, updatedWhite) => API.patch(`/whites/${id}`, updatedWhite)
export const deleteWhite =(id) => API.delete(`/whites/${id}`)
export const fetchWhitesByUser = (searchQuery) => API.get(`/whites?searchQuery=${searchQuery.search}`);

export const fetchClient = (id) => API.get(`/clients/${id}`);
export const fetchClients = (page) => API.get(`/clients?page=${page}`);
export const addClient =( client ) => API.post('/clients', client)
export const updateClient = (id, updatedClient) => API.patch(`/clients/${id}`, updatedClient)
export const deleteClient =(id) => API.delete(`/clients/${id}`)
export const fetchClientsByUser = (searchQuery) => API.get(`/clients/user?searchQuery=${searchQuery.search}`);

export const fetchProduct = (id) => API.get(`/products/${id}`);
export const fetchProducts = (page) => API.get(`/products?page=${page}`);
export const addProduct =( product ) => API.post('/products', product)
export const updateProduct = (id, updatedProduct) => API.patch(`/products/${id}`, updatedProduct)
export const deleteProduct =(id) => API.delete(`/products/${id}`)
export const fetchProductsByUser = (searchQuery) => API.get(`/products/user?searchQuery=${searchQuery.search}`);

export const fetchRet = (date) => API.get(`/rets/${date}`);
export const fetchRets = (page) => API.get(`/rets?page=${page}`);
export const addRet =( ret ) => API.post('/rets', ret)
export const updateRet = (date, updatedRet) => API.patch(`/rets/${date}`, updatedRet)
export const deleteRet =(id) => API.delete(`/rets/${id}`)
export const fetchRetsByUser = (searchQuery) => API.get(`/rets/user?searchQuery=${searchQuery.search}`);

export const fetchLockup = (id) => API.get(`/lockups/${id}`);
export const fetchLockups = (page) => API.get(`/lockups?page=${page}`);
export const addLockup =( lockup ) => API.post('/lockups', lockup)
export const updateLockup = (id, updatedLockup) => API.patch(`/lockups/${id}`, updatedLockup)
export const deleteLockup =(id) => API.delete(`/lockups/${id}`)
export const fetchLockupsByUser = (searchQuery) => API.get(`/lockups/user?searchQuery=${searchQuery.search}`);

export const fetchInventory = (id) => API.get(`/inventory/${id}`);
export const fetchInventories = (page) => API.get(`/inventory?page=${page}`);
export const addInventory =( inventory ) => API.post('/inventory', inventory)
export const updateInventory = (id, updatedInventory) => API.patch(`/inventory/${id}`, updatedInventory)
export const deleteInventory =(id) => API.delete(`/inventory/${id}`)
export const fetchInventoriesByUser = (searchQuery) => API.get(`/inventory/user?searchQuery=${searchQuery.search}`);

export const fetchRoute = (id) => API.get(`/routes/${id}`);
export const fetchRoutes = (page) => API.get(`/routes?page=${page}`);
export const addRoute =( client ) => API.post('/routes', client)
export const updateRoute = (id, updatedRoute) => API.patch(`/routes/${id}`, updatedRoute)
export const deleteRoute =(id) => API.delete(`/routes/${id}`)
export const fetchRoutesByUser = (searchQuery) => API.get(`/routes/user?searchQuery=${searchQuery.search}`);

export const signIn =(formData)=> API.post('/users/signin', formData)
export const signUp =(formData)=> API.post('/users/signup', formData)
export const forgot = (formData) => API.post('/users/forgot', formData);
export const reset = (formData) => API.post('/users/reset', formData);

export const fetchProfilesBySearch = (searchQuery) => API.get(`/profiles/search?searchQuery=${searchQuery.search || searchQuery.year || 'none'}`);
export const fetchProfile = (id) => API.get(`/profiles/${id}`)
export const fetchProfiles = () => API.get('/profiles');
export const fetchProfilesByUser = (searchQuery) => API.get(`/profiles?searchQuery=${searchQuery.search}`)
export const createProfile = (newProfile) => API.post('/profiles', newProfile);
export const updateProfile = (id, updatedProfile) => API.patch(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.delete(`/profiles/${id}`);

export const fetchOrderSheet = (id) => API.get(`/order-sheet/${id}`)

export const sendPdf = () => API.post(`/pdfs/send-pdf`)